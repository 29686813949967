import axios from "axios";

const levels = {
    state: () => ({
        levels: [{id: '', name: ""}]
    }),
    mutations: {
        set_levels(state, data) {
            state.levels = data;
        }
    },
    actions: {
        async getLevels({commit}){
            const levels = await axios.get('levels').then(response => response.data)
            commit('set_levels', levels)
        }
    },
    namespaced: true,
}
export default levels;