<template>
    <div class="bl-sidebar-menu-items">
        <sidebar-link title="sidebar.home" link="/home">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8594 6.50353V1.49541H13.5156V4.16951L10 0.668457L0 10.627V11.847H2.38281V18.3313H8.61981V14.4251H11.3542V18.3313H17.6172V11.847H20V10.627L15.8594 6.50353Z" fill="white"/>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.themes" link="/themes">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24858 0.312012H0.265625V4.26027H4.24858V0.312012Z" fill="white"/><path d="M18.0003 0.312012H5.24902V4.26027H18.0003V0.312012Z" fill="white"/><path d="M0.265625 5.25146H4.24858V9.19972H0.265625V5.25146Z" fill="white"/><path d="M18.0003 5.25195H5.24902V9.20021H18.0003V5.25195Z" fill="white"/><path d="M0.265625 10.1914H4.24858V14.1398H0.265625V10.1914Z" fill="white"/><path d="M18.0003 10.1914H5.24902V14.1398H18.0003V10.1914Z" fill="white"/>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.lessons" link="/lessons">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 6.01322L18.7891 6.67369V11.7341L20 14.3319L18.2031 16.2168L16.4062 14.3319L17.6172 11.7341V7.28303L15.8594 8.19705V6.97455C15.8084 6.95513 15.7204 6.90946 15.5972 6.84549C14.8626 6.46416 12.8751 5.43244 10 5.43244C7.14778 5.43244 5.10646 6.48301 4.38455 6.85455C4.26835 6.91435 4.18634 6.95656 4.14062 6.97396V8.19705L0 6.01322L10 0.782715L20 6.01322Z" fill="white"/>
                <path d="M5.3125 12.4587V7.69969C6.74988 6.98328 8.37035 6.60449 10 6.60449C11.6296 6.60449 13.2507 6.98328 14.6875 7.69969V12.4587H5.3125Z" fill="white"/>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.cards" link="/cards">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 6.5V3.5C7 3.23478 7.10536 2.98043 7.29289 2.79289C7.48043 2.60536 7.73478 2.5 8 2.5H20C20.2652 2.5 20.5196 2.60536 20.7071 2.79289C20.8946 2.98043 21 3.23478 21 3.5V17.5C21 17.7652 20.8946 18.0196 20.7071 18.2071C20.5196 18.3946 20.2652 18.5 20 18.5H17V21.5C17 22.052 16.55 22.5 15.993 22.5H4.007C3.87513 22.5008 3.7444 22.4755 3.62232 22.4256C3.50025 22.3757 3.38923 22.3022 3.29566 22.2093C3.20208 22.1164 3.12779 22.0059 3.07705 21.8841C3.02632 21.7624 3.00013 21.6319 3 21.5L3.003 7.5C3.003 6.948 3.453 6.5 4.01 6.5H7ZM9 6.5H17V16.5H19V4.5H9V6.5ZM7 11.5V13.5H13V11.5H7ZM7 15.5V17.5H13V15.5H7Z" fill="white"/>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.drafts" link="/drafts">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1667 0.5C14.6267 0.5 15 0.873333 15 1.33333V4.46417L7.50083 11.9642L7.49583 15.4958L11.0342 15.5008L15 11.535V16.3333C15 16.7933 14.6267 17.1667 14.1667 17.1667H0.833333C0.373333 17.1667 0 16.7933 0 16.3333V1.33333C0 0.873333 0.373333 0.5 0.833333 0.5H14.1667ZM15.6483 6.17333L16.8267 7.35167L10.345 13.8333L9.165 13.8317L9.16667 12.655L15.6483 6.17333ZM7.5 8.83333H3.33333V10.5H7.5V8.83333ZM10 5.5H3.33333V7.16667H10V5.5Z" fill="white"/>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.admins" link="/admins">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5292_7821)">
                    <path d="M13.6364 9.5C15.1455 9.5 16.3545 8.16 16.3545 6.5C16.3545 4.84 15.1455 3.5 13.6364 3.5C12.1273 3.5 10.9091 4.84 10.9091 6.5C10.9091 8.16 12.1273 9.5 13.6364 9.5ZM6.36364 9.5C7.87273 9.5 9.08182 8.16 9.08182 6.5C9.08182 4.84 7.87273 3.5 6.36364 3.5C4.85455 3.5 3.63636 4.84 3.63636 6.5C3.63636 8.16 4.85455 9.5 6.36364 9.5ZM6.36364 11.5C4.24545 11.5 0 12.67 0 15V17.5H12.7273V15C12.7273 12.67 8.48182 11.5 6.36364 11.5ZM13.6364 11.5C13.3727 11.5 13.0727 11.52 12.7545 11.55C13.8091 12.39 14.5455 13.52 14.5455 15V17.5H20V15C20 12.67 15.7545 11.5 13.6364 11.5Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_5292_7821">
                        <rect width="19.0476" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </sidebar-link>

        <sidebar-link title="sidebar.errors" link="/errors">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6002 15.0722L12.5933 2.9358C11.4489 0.930905 8.55474 0.930905 7.41438 2.9358L0.403419 15.0722C-0.741059 17.0771 0.683363 19.5678 2.9929 19.5678H16.986C19.2955 19.5678 20.7447 17.0524 19.6002 15.0722ZM9.99974 16.8754C9.39045 16.8754 8.88408 16.369 8.88408 15.7597C8.88408 15.1504 9.39045 14.6441 9.99974 14.6441C10.609 14.6441 11.1154 15.1504 11.0907 15.7885C11.1195 16.369 10.5843 16.8754 9.99974 16.8754ZM11.0166 9.66269C10.9672 10.5272 10.9137 11.3876 10.8643 12.2522C10.8396 12.5321 10.8396 12.7874 10.8396 13.0632C10.8149 13.5202 10.4567 13.8742 9.99974 13.8742C9.54278 13.8742 9.18873 13.5449 9.15991 13.0879C9.08581 11.7417 9.00759 10.4202 8.93349 9.07399C8.90878 8.71994 8.88408 8.36178 8.85527 8.00773C8.85527 7.42314 9.18461 6.94147 9.7198 6.78915C10.255 6.66153 10.7861 6.91677 11.0166 7.42314C11.0948 7.60016 11.1195 7.77719 11.1195 7.98303C11.0948 8.54704 11.0413 9.10692 11.0166 9.66269Z" fill="white"/>
            </svg>
        </sidebar-link>
    </div>
    <div class="bl-sidebar-menu-logout">
        <div class="bl-sidebar-link" style="cursor:pointer" @click="logoutUser">
            <a>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5292_7153)">
                        <path d="M12.0019 11.75C12.0019 11.336 12.3372 11 12.7519 11C13.1667 11 13.5019 11.336 13.5019 11.75V16.25C13.5019 16.664 13.1667 17 12.7519 17H9.00192V17.75C9.00192 17.975 8.90067 18.1873 8.72742 18.3298C8.59242 18.4408 8.42367 18.5 8.25193 18.5C8.2032 18.5 8.1537 18.4955 8.10494 18.485L0.604953 16.985C0.254691 16.9153 0.00195312 16.6077 0.00195312 16.25V1.24999C0.00195312 1.24223 0.00457821 1.23486 0.00721019 1.22748C0.00965671 1.22061 0.0121092 1.21373 0.0124648 1.2065C0.0127712 1.19747 0.0117882 1.18857 0.0108019 1.17965C0.00941128 1.16706 0.00801402 1.15441 0.0102148 1.14125C0.0181143 1.08595 0.0392848 1.03643 0.0605805 0.986607C0.0635474 0.979666 0.0665168 0.972719 0.0694531 0.96575C0.0728786 0.957011 0.0752678 0.948137 0.0776487 0.939295C0.0809858 0.926901 0.0843066 0.914568 0.0904414 0.90275C0.139941 0.808988 0.20818 0.730238 0.289918 0.665762C0.292921 0.663514 0.294985 0.660328 0.297053 0.657136C0.299125 0.653938 0.3012 0.650735 0.304227 0.648465C0.312672 0.642037 0.322411 0.638843 0.33217 0.635643C0.340611 0.632875 0.349068 0.630102 0.356715 0.625227C0.423441 0.58325 0.494703 0.552488 0.573453 0.532238C0.607203 0.523977 0.639441 0.518 0.674703 0.514238C0.685812 0.512973 0.696523 0.510225 0.707233 0.507477C0.721807 0.503738 0.736378 0.5 0.751942 0.5H12.7519C13.1667 0.5 13.5019 0.835988 13.5019 1.25002V5.75002C13.5019 6.16402 13.1667 6.50001 12.7519 6.50001C12.3372 6.50001 12.0019 6.16402 12.0019 5.75002V2.00001H5.86243L8.46719 2.7815C8.78444 2.87674 9.00195 3.16924 9.00195 3.49999V15.5H12.0019V11.75Z" fill="white"/>
                        <path d="M17.7805 8.21864C17.8502 8.28839 17.905 8.37164 17.9432 8.46315C18.019 8.64614 18.019 8.85314 17.9432 9.03606C17.905 9.12831 17.8502 9.21156 17.7805 9.28057L15.5312 11.5298C15.385 11.6769 15.193 11.7496 15.001 11.7496C14.809 11.7496 14.617 11.6768 14.4707 11.5298C14.1775 11.2373 14.1775 10.7626 14.4707 10.4693L15.4405 9.49959H11.251C10.8362 9.49959 10.501 9.1636 10.501 8.7496C10.501 8.3356 10.8362 7.99961 11.251 7.99961H15.4405L14.4707 7.02986C14.1775 6.73736 14.1775 6.26261 14.4707 5.96938C14.764 5.67688 15.238 5.67688 15.5312 5.96938L17.7805 8.21864Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_5292_7153">
                            <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
                <div>{{$t('sidebar.logout')}}</div>
            </a>
        </div>
    </div>
</template>
<script>
import SidebarLink from "./SidebarLink";
import {mapActions} from "vuex";

export default {
    components: {
        SidebarLink
    },
    props: {

    },
    data() {
        return {

        };
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
        }),
        logoutUser: function () {
            this.logout()
                .then(response => {
                    this.$router.push('login')
                })
        },
    }
};
</script>

<style scoped>

</style>