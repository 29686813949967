import LoginPage from "@/pages/LoginPage";
import NotFound from "@/pages/NotFound";
import PasswordRecovery from "@/pages/PasswordRecovery";
import CheckEmail from "@/pages/CheckEmail";
import NewPassword from "@/pages/NewPassword";
import SuccessfulPasswordRecovery from "@/pages/SuccesfullPasswordRecovery";
import DashboardLayout from "@/layouts/DashboardLayout";
import HomePage from '@/pages/HomePage'
import ThemesPage from '@/pages/ThemesPage'
import LessonsPage from '@/pages/LessonsPage'
import CardsPage from '@/pages/CardsPage'
import DraftsPage from '@/pages/DraftsPage'
import AdminsPage from '@/pages/AdminsPage'
import ErrorsPage from '@/pages/ErrorsPage'
import CreateTheme from "@/pages/CreateTheme";
import EditTheme from "@/pages/EditTheme";
import CreateLesson from "@/pages/CreateLesson";
import EditLesson from "@/pages/EditLesson";

const routes = [
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "Home",
                component: HomePage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/themes",
                name: "Themes",
                component: ThemesPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/themes/create",
                name: "CreateTheme",
                component: CreateTheme,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/themes/edit/:id",
                name: "EditTheme",
                component: EditTheme,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/lessons",
                name: "Lessons",
                component: LessonsPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/lessons/create",
                name: "CreateLesson",
                component: CreateLesson,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/lessons/edit/:id",
                name: "EditLesson",
                component: EditLesson,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/cards",
                name: "Cards",
                component: CardsPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/drafts",
                name: "Drafts",
                component: DraftsPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/admins",
                name: "Administrators",
                component: AdminsPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/errors",
                name: "Errors",
                component: ErrorsPage,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/password-recovery',
        component: PasswordRecovery,
    },
    {
        path: '/check-email',
        component: CheckEmail,
    },
    {
        path: '/new-password',
        component: NewPassword,
    },
    {
        path: '/successful-password-recovery',
        component: SuccessfulPasswordRecovery,
    },
    { path: "/:pathMatch(.*)*", component: NotFound },



];

export default routes