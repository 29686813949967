import routes from "./routes";
import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async to => {
  if (to.meta.requiresAuth && !store.state.auth.isLoggedIn) {
    try {
      await store.dispatch('auth/user')
    } catch (error) {
      if (error) {
        return {
          path: '/login',
        }
      } else {
        throw error
      }
    }
  }
})

// router.beforeEach((to, from) => {
//   if (to.meta.requiresAuth) {
//     if (to.meta.requiresAuth && !store.state.auth.isLoggedIn) {
//       // this route requires auth, check if logged in
//       // if not, redirect to login page.
//       return {
//         path: '/login',
//       }
//     }
//   }
// })

export default router
