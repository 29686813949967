<template>
  <button :disabled="!activeButton" type="submit" v-bind:class="{ 'btn-primary':activeButton, 'btn-inactive':!activeButton }"
          @click="click" class="btn button w-100"><slot></slot></button>
</template>

<script>
export default {
  name: 'submit-button',
  props: {
    activeButton: [Boolean],
  },
  methods: {
    click() {
      this.$emit('submit','true')
    }
  }
}
</script>

<style scoped>
.button {
  margin-top: 15px;
}
.btn-inactive {
  background-color: #81B6E7;
}
</style>