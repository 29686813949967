<template>
    <div class="d-flex align-items-center justify-content-between">
        <div class="bl-top-navbar-title">{{$t(this.$route.name)}}</div>
    </div>
</template>

<script>
export default {
    name: "TopNavbar"
}
</script>

<style scoped>

</style>