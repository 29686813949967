export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
  "Themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теми"])},
  "Lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уроки"])},
  "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картки"])},
  "Drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернетки"])},
  "Administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратори"])},
  "Errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилки"])},
  "button": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])}
  },
  "login": {
    "username": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я користувача"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть ім'я користувача"])}
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть пароль"])}
    },
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
    "errors": {
      "Invalid username or password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірні ім'я користувача або пароль"])}
    }
  },
  "recovery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть вашу пошту"])}
    }
  },
  "sidebar": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
    "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теми"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уроки"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картки"])},
    "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернетки"])},
    "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратори"])},
    "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилки"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихід"])}
  },
  "admins": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я користувача"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  }
}