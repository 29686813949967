import SidebarPlugin from "@/components/SidebarPlugin/index";


//css assets
import "@/assets/scss/main.scss";


export default {
    install(app, options) {
        app.use(SidebarPlugin);
    }
}