<template>
  <locale-dropdown/>
  <div class="form_wrap d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="image">
        <img src="@/assets/sloth.svg" alt="sloth">
      </div>
      <form @submit.prevent>
        <p class="title">{{ $t('recovery.title') }}</p>
        <error-block v-if="error">{{ errorMessage }}</error-block>
        <div class="form-group">
          <label for="email">{{ $t('recovery.email.label') }}</label>
          <input type="text" v-model="email" class="form-control border-class email" id="email" :placeholder="$t('recovery.email.placeholder')">
        </div>
        <submit-button @submit="sendForm" :active-button="activeButton">{{ $t('button.continue') }}</submit-button>
        <router-link to="/login" class="btn button w-100 btn-outline-primary">{{ $t('button.back') }}</router-link>
      </form>
    </div>
  </div>
</template>

<script>
import LocaleDropdown from '@/components/form/localeDropdown'
import ErrorBlock from "@/components/errorBlock";
import SubmitButton from "@/components/form/submitButton";
import axios from "axios";
import CheckEmail from "@/pages/CheckEmail";
export default {
  components: {LocaleDropdown, ErrorBlock, SubmitButton},
  data() {
    return {
      email: '',
      activeButton: false,
      errorMessage: '',
      error: false
    }
  },
  methods: {
    sendForm() {
      const errorBlock = document.querySelector('.error-message');
      const emailInput = document.getElementById('email');

      if (this.validateEmail()) {
        axios.post('/password/sendRestoreEmail', {
          'email': emailInput.value
        })
        .then((response)=>{
          this.$router.push({
            path: '/check-email',
            query: {
              email: emailInput.value,
            },
          });
        }, (error) => {
            this.errorMessage = error.response.data.message
            this.error = true
        });
      } else {
          this.errorMessage = 'Введите почту в формате “mail@email.com'
          this.error = true
      }
    },

    validateEmail() {
      const email = this.email;
      const pattern = /[a-zA-Z0-9]+@[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}/
      return pattern.test(email)
    }
  },
  watch: {
    email(value) {
      if (value !== '')
        return this.activeButton = true;
      else
        return this.activeButton = false;
    }
  }
}
</script>

<style scoped>
.form-control {
  border: 2px solid #E9F4FF;
  box-shadow: none;
}
.container {
  width: 343px;
}
.button {
  margin-top: 15px;
}
label {
  color: #999999;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.form_wrap {
  min-height: 100vh;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #1E86EA;
}
</style>