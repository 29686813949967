const cardModal = {
    state: () => ({
        form: [],
    }),
    mutations: {
        set(state, form) {
            state.form = form
        },
        reset(state) {
            state.form = [];
        }
    },
    actions: {
    },
    getters: {
    },
    namespaced: true,
}
export default cardModal;