<template>
    <div>
        <div class="create">
            <span @click="create" class="create-span" data-target="#cardModal" data-toggle="modal">+Создать</span>
        </div>
        <card-modal/>

        <component-table :titles="['id', 'Слово', 'Перевод ру', 'Перевод укр', 'Уроки', 'Статус']" :data="cards"/>
    </div>
</template>

<script>
import CardModal from "@/components/Admins/CardModal";
import {mapActions, mapState} from "vuex";
import ComponentTable from "@/components/Admins/Table";
export default {
    name: "CardsPage",
    components: {ComponentTable, CardModal},
    methods: {
        ...mapActions({
            getCards: 'cards/getCards',
        }),
        create() {
            this.$store.commit('cardModal/reset');
        },
    },
    computed: {
        ...mapState({
            cards: state => state.cards.cards,
        })
    },
    mounted() {
        this.getCards();
    }
}
</script>

<style scoped>
    .create {
        color: #0d6efd;
        margin-bottom: 10px;
    }
    .create-span:hover{
        cursor: pointer;
    }
</style>