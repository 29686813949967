import axios from "axios";
import router from "@/router";

const auth = {
    state: () => ({
        isLoggedIn: false,
        user: {
            username: null,
            email: null
        }
    }),
    mutations: {
        set_user(state, payload) {
            state.user.username = payload.username
            state.user.email = payload.email
        },
        authenticate(state) {
            state.isLoggedIn = true
        },
        unauthenticate(state) {
            state.isLoggedIn = false
        }
    },
    actions: {
        async login({commit, state}, credentials){
            return await new Promise((resolve, reject) => {
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post('/login', {
                        username: credentials.username,
                        password: credentials.password
                    }).then(response => {
                        commit('set_user', response.data.data.user)
                        commit('authenticate')
                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
                });

            })
        },
        async logout({commit}) {
            await axios.post('/logout').then(response => {
                return response.data;
            })
            commit('unauthenticate');
        },
        async user({commit}) {
            const user = await axios.get('/user').then(response => {
                return response.data.data.user
            })
            commit('set_user', user)
            commit('authenticate')
        }
    },
    getters: {
        user (state) {
            return state.user
        }
    },
    namespaced: true,
}
export default auth;