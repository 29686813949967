import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Dashboard from "./plugins/Dashboard";
import axios from "axios";
import VueAxios from 'vue-axios'
import i18n from './i18n'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(undefined, function (error) {
      if (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {

                  originalRequest._retry = true;
                  // store.dispatch('logout')
                  return router.push('/login')
            }
            return Promise.reject(error);
      }
})
const app = createApp(App)
app.use(store)
   .use(router)
   .use(i18n)
   .use(Dashboard)
   .use(VueAxios, axios)
   .mount('#app')

app.config.globalProperties.$http = axios;
