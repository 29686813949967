<template>
    <div class="bl-admins-list">
        <router-link to="/admins/create">+ {{$t('button.create')}}</router-link>
        <simple-table>
            <template v-slot:th>
                <div class="row">
                    <div class="d-none d-lg-block col-1 p-0"></div>
                    <div class="col-3 p-0">{{ $t('admins.login') }}</div>
                    <div class="col-3 p-0">{{ $t('admins.username') }}</div>
                    <div class="col-4 p-0">{{ $t('admins.email') }}</div>
                    <div class="col-1"></div>
                </div>
            </template>
            <template v-slot:tb>
                <div v-for="admin in admins">
                    <t-row>
                        <div class="d-none d-lg-block col-1 p-0"></div>
                        <div class="d-flex align-items-center col-3 p-0">{{admin.login}}</div>
                        <div class="d-flex align-items-center col-3 p-0">{{admin.username}}</div>
                        <div class="d-flex align-items-center col-4 p-0">{{admin.email}}</div>
                        <div class="col-1 d-flex justify-content-around align-items-center">
                            <button class="btn shadow-none bg-transparent">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 3.2H16V4.8H14.4V15.2C14.4 15.4122 14.3157 15.6157 14.1657 15.7657C14.0157 15.9157 13.8122 16 13.6 16H2.4C2.18783 16 1.98434 15.9157 1.83431 15.7657C1.68429 15.6157 1.6 15.4122 1.6 15.2V4.8H0V3.2H4V0.8C4 0.587827 4.08429 0.384344 4.23431 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H11.2C11.4122 0 11.6157 0.0842854 11.7657 0.234315C11.9157 0.384344 12 0.587827 12 0.8V3.2ZM12.8 4.8H3.2V14.4H12.8V4.8ZM9.1312 9.6L10.5456 11.0144L9.4144 12.1456L8 10.7312L6.5856 12.1456L5.4544 11.0144L6.8688 9.6L5.4544 8.1856L6.5856 7.0544L8 8.4688L9.4144 7.0544L10.5456 8.1856L9.1312 9.6ZM5.6 1.6V3.2H10.4V1.6H5.6Z" fill="#091E31"/>
                                </svg>
                            </button>
                            <button class="btn shadow-none bg-transparent">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.60222 4.68222L9.31778 5.39778L2.27111 12.4444H1.55556V11.7289L8.60222 4.68222ZM11.4022 0C11.2078 0 11.0056 0.0777778 10.8578 0.225556L9.43444 1.64889L12.3511 4.56556L13.7744 3.14222C14.0778 2.83889 14.0778 2.34889 13.7744 2.04556L11.9544 0.225556C11.7989 0.07 11.6044 0 11.4022 0ZM8.60222 2.48111L0 11.0833V14H2.91667L11.5189 5.39778L8.60222 2.48111Z" fill="#091E31"/>
                                </svg>
                            </button>
                        </div>
                    </t-row>
                </div>
            </template>
        </simple-table>
    </div>
</template>

<script>
import simpleTable from "../../components/Table/TableComponent"
import tRow from "../../components/Table/TableRowComponent"
import {mapActions, mapState} from "vuex";
export default {
    name: "List",
    components: {
        simpleTable,
        tRow
    },
    data() {
        return {
            // admins:
            // admins: [
            //     {
            //         login: "admin",
            //         username: "Администратор",
            //         email: "admin@vrgsoft.net"
            //     },
            //     {
            //         login: "semeniuk",
            //         username: "Александр Семенюк",
            //         email: "semeniuk@vrgsoft.net"
            //     },
            //     {
            //         login: "mary",
            //         username: "Мария",
            //         email: "ribka95@vrgsoft.net"
            //     },
            //     {
            //         login: "lizok",
            //         username: "Лиза Капуста",
            //         email: "babosik@vrgsoft.net"
            //     }
            // ]
        }
    },
    mounted() {
        this.getAdmins()
    },
    computed: {
        ...mapState({
            admins: state => state.admins.admins
        })
    },
    methods: {
        ...mapActions({
            getAdmins: 'admins/getAdmins',
        }),

    }
}
</script>

<style scoped>

</style>