<template>
    <div>
        <list></list>
    </div>
</template>

<script>
import List from "../components/Admins/List"
export default {
    name: "AdminsPage",
    components: {
        List
    }
}
</script>

<style scoped>

</style>