<template>
    <div>Not found</div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>