import axios from "axios";

const questionTypes = {
    state: () => ({
        questionTypes: [{id: '', 'title': ''}],
    }),
    mutations: {
        set_question_types(state, data) {
            state.questionTypes = data;
        }
    },
    getters: {
        questionTypeGetter(state) {
            state.questionTypes.forEach(value => [3,8].includes(value.id) ? value.title += ' (image)' : value);
            return state.questionTypes;
        }
    },
    actions: {
        async getQuestionTypes({commit}){
            const questionTypes = await axios.get('questionTypes').then(response => response.data)
            commit('set_question_types', questionTypes)
        }
    },
    namespaced: true,
}
export default questionTypes;