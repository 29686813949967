<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th v-for="title in titles">{{ title }}</th>
                <th>Действия</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data">
                <th>{{ item.id }}</th>
                <th>{{ item.word }}</th>
                <th>{{ item.translation }}</th>
                <th>{{ item.translation_ua }}</th>
                <th>
                    <span class="d-block" v-for="lesson in lessons(item)">{{ lesson }}</span>
                </th>
                <th>{{ item.status == 1 ? 'Опубликовано' : 'Черновик' }}</th>
                <th class="action-row">
                    <button class="btn btn-warning button-margin" @click="update(item)" data-toggle="modal" data-target="#cardModal">Редактировать</button>
                    <button class="btn btn-danger" @click="remove(item.id)">Удалить</button>
                </th>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "ComponentTable",
    props: {titles: Array, data: Array},
    computed: {
        lessons: () => item => item.lessons.map(item => item.name)
    },
    methods: {
        ...mapActions({
            deleteCard: 'cards/deleteCard'
        }),
        remove(id) {
            this.deleteCard(id);
        },
        update(item) {
            this.$store.commit('cardModal/set', item)
        }
    }
}
</script>

<style scoped>
    .action-row {
        width: 17%;
    }
    .button-margin {
        margin-right: 10px;
    }
</style>