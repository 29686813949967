<template>
  <locale-dropdown :current-lang="currentLang" :other-langs="otherLangs"/>
  <div class="form_wrap d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="image">
        <img src="@/assets/sloth.svg" alt="sloth">
      </div>
      <p class="title">Проверьте свою электронную почту!</p>
      <p class="text">Мы отправили письмо на почту {{ this.$route.query.email }}. Не получили его? Проверьте папку со спамом или повторите попытку</p>
      <router-link to="/login" class="btn w-100 btn-primary">Окей</router-link>
    </div>
  </div>
</template>

<script>
import LocaleDropdown from '@/components/form/localeDropdown'

export default {
  components: {LocaleDropdown},
  data() {
    return {
      currentLang: 'RU',
      otherLangs: [
        {'name': "UA",},
      ],
    }
  }
}
</script>

<style scoped>
.container {
  width: 374px;
}
label {
  color: #999999;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.form_wrap {
  min-height: 100vh;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #1E86EA;
}
.text {
  text-align: center;
  color: #999999;
  font-weight: 400;
  font-size: 14px;
}
</style>