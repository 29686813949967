export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themes"])},
  "Lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
  "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "Drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drafts"])},
  "Administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admins"])},
  "Errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
  "button": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
  },
  "login": {
    "username": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter username"])}
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])}
    },
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "errors": {
      "Invalid username or password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])}
    }
  },
  "recovery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])}
    }
  },
  "sidebar": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themes"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
    "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drafts"])},
    "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admins"])},
    "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "admins": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  }
}