import axios from "axios";
import router from "@/router";

const lessons = {
    state: () => ({
        lessons: [
            {
                id: '',
                levelName: '',
                name: '',
                themeName: '',
                questionsAmount: '',
                status: '',
            }
        ],
    }),
    getters: {
        getAllLessons(state) {
            return state.lessons;
        }
    },
    mutations: {
        updateLessons(state, data) {
            state.lessons = data;
        }
    },
    actions: {
        async getLessons({ commit }) {
            return await new Promise(() => {
                axios.get('lessons')
                    .then(response => {
                        commit('updateLessons', response.data.data);
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
        async createLesson({ commit, state }, data) {
            return await new Promise(() => {
                axios.post('lessons', data)
                    .then(response => {
                        router.push('/lessons/edit/' + response.data.data.id)
                        alert('Урок успешно создан')
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
        async getLessonById({ commit, state }, id) {
            return await new Promise((resolve) => {
                axios.get('lessons/' + id)
                    .then(response => {
                        resolve(response.data.data)
                    }).catch(error => {
                    alert(Object.values(error.response.data.errors).join('\n'));
                })
            });
        },
        async deleteLesson({ commit, state }, id) {
            return await new Promise(() => {
                if (confirm('Удалить этот урок?'))
                    axios.delete('lessons/' + id)
                        .then(response => {
                            alert(response.data.message)
                        }).catch(error => {
                            alert(Object.values(error.response.data.errors).join('\n'));
                    })
            });
        },
        async updateLesson({ commit, state }, data) {
            return await new Promise(() => {
                axios.put('lessons/' + data.id, data)
                    .then(response => {
                        alert('Урок успешно обновлен')
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
    },
    namespaced: true,
}
export default lessons;