import axios from "axios";

const cards = {
    state: () => ({
        cards: [{
            id: '',
            word: '',
            translation: '',
            status: '',
            lessons: [{id: '', name: ''}]
        }],
    }),
    mutations: {
        set_cards(state, data) {
            state.cards = data;
        },
        add_card(state, data) {
            state.cards.push(data);
        },
        delete_card(state, id) {
            const index = state.cards.findIndex(item => item.id === id);
            state.cards.splice(index, 1);
        },
        update_card(state, data) {
            const index = state.cards.findIndex(item => item.id === data.id);
            state.cards[index] = data;
        }
    },
    actions: {
        async getCards({commit}){
            const cards = await axios.get('cards').then(response => {
                return response.data.data
            })
            commit('set_cards', cards)
        },
        async createCard({ commit, state }, data) {
            return await new Promise(() => {
                axios.post('cards', data)
                    .then(response => {
                        commit('add_card', response.data.data)
                        alert('Карточка успешно сохранена')
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
        async deleteCard({ commit, state }, id) {
            return await new Promise(() => {
                if (confirm('Удалить эту карточку?'))
                    axios.delete('cards/' + id)
                        .then(response => {
                            commit('delete_card', id)
                            alert(response.data.message)
                        }).catch(error => {
                            alert(Object.values(error.response.data.errors).join('\n'));
                    })
            });
        },
        async updateCard({ commit, state }, data) {
            return await new Promise(() => {
                axios.put('cards/' + data.id, data)
                    .then(response => {
                        commit('update_card', data)
                        alert("Карточка успешно обновлена")
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
    },
    getters: {

    },
    namespaced: true,
}
export default cards;