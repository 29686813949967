<template>
  <div class="error-message d-flex justify-content-center align-items-center">
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50002 2.17773C8.79612 2.17773 8.06785 2.76339 8.1155 3.60188L8.41165 11.598C8.41663 11.8798 8.53071 12.1489 8.73031 12.3485C8.93445 12.5526 9.21133 12.6673 9.50002 12.6673C9.78872 12.6673 10.0656 12.5526 10.2697 12.3485C10.4693 12.1489 10.5834 11.8798 10.5884 11.598L10.8846 3.60188C10.9322 2.76338 10.2039 2.17773 9.50002 2.17773Z" fill="#F64545"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50007 14.0527C8.73493 14.0527 8.11465 14.673 8.11465 15.4382C8.11465 16.2033 8.73493 16.8236 9.50007 16.8236C10.2652 16.8236 10.8855 16.2033 10.8855 15.4382C10.8855 14.673 10.2652 14.0527 9.50007 14.0527Z" fill="#F64545"/>
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'error-block'
}
</script>

<style scoped>
.error-message {
  margin: 5px 0;
  /*visibility: hidden;*/
  background-color: #FFE8E8;
  color: #F64545;
  border-radius: 7px;
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
}
</style>