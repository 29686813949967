<template>
    <div class="bl-table-row bl-table-row-text">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TableRowComponent"
}
</script>

<style lang="scss" scoped>
.bl-table-row {
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    padding: 19px 10px;
}

.bl-table-row-text {
    font-family: 'Open Sans', 'serif';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #091E31;
}
</style>