export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "Themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темы"])},
  "Lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уроки"])},
  "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточки"])},
  "Drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновики"])},
  "Administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администраторы"])},
  "Errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибки"])},
  "button": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])}
  },
  "login": {
    "username": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя пользователя"])}
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
    },
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "errors": {
      "Invalid username or password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверные имя пользователя или пароль"])}
    }
  },
  "recovery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу почту"])}
    }
  },
  "sidebar": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темы"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уроки"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточки"])},
    "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновики"])},
    "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администраторы"])},
    "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибки"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])}
  },
  "admins": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  }
}