import axios from "axios";
import router from "@/router";

const themes = {
    state: () => ({
        themes: [{
            id: '',
            name: '',
            level: '',
            description: '',
            lessons_amount: '',
            status: '',
        }],
    }),
    mutations: {
        updateThemes(state, data) {
            state.themes = data;
        }
    },
    actions: {
         async getThemes({ commit }) {
             return await new Promise(() => {
                 axios.get('themes')
                     .then(response => {
                         commit('updateThemes', response.data.data);
                     }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                     })
             })
        },
        async updateTheme({ commit, state }, data) {
            return await new Promise(() => {
                axios.put('themes/' + data.id, data)
                    .then(response => {
                        alert(response.data.message)
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
        async deleteTheme({ commit, state }, id) {
            return await new Promise(() => {
                if (confirm('Удалить эту тему?'))
                    axios.delete('themes/' + id)
                        .then(response => {
                            alert(response.data.message)
                        }).catch(error => {
                            alert(Object.values(error.response.data.errors).join('\n'));
                    })
            });
        },
        async getThemeById({ commit, state }, id) {
             return await new Promise((resolve) => {
                 axios.get('themes/' + id)
                     .then(response => {
                          resolve(response.data.data)
                     }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                 })
             });
        },
        async createTheme({ commit, state }, data) {
            return await new Promise(() => {
                axios.post('themes', data)
                    .then(response => {
                        router.push('/themes/edit/' + response.data.data.id)
                        alert('Тема успешно создана')
                    }).catch(error => {
                        alert(Object.values(error.response.data.errors).join('\n'));
                })
            })
        },
    },
    getters: {
        getAllThemes(state) {
            return state.themes;
        },
        getAllThemesNames(state) {
            return state.themes.map(theme => {
                return {
                    'id': theme.id,
                    'name': theme.name + ' (' + theme.level + ')'
                }
            });
        },
    },
    namespaced: true,
}
export default themes;