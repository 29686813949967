<template>
    <div class="wrap">
        <div v-for="(error, key) in errors">
            {{ key }}
            <div v-for="item in error">
                <div class="from">{{ item.sentFrom }}</div>
                <div class="feed">{{ item.message }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "ErrorsPage",
    mounted() {
        this.getFeed()
    },
    computed: {
        ...mapState({
            errors: state => state.errors.errors
        })
    },
    methods: {
        ...mapActions({
            getFeed: 'errors/getErrors',
        }),

    }
}
</script>

<style scoped>
    .feed {
        width: 100%;
        border: 2px solid #E9F4FF;
        border-radius: 12px;
        padding: 12px;
        margin-bottom: 10px;
    }
    .wrap {
        margin-top: 20px;
    }
    .from {
        color: #1E86EA;
        width: 100%;
        text-align: right;
    }
</style>