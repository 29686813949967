import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import admins from "@/store/modules/admins";
import themes from "@/store/modules/themes"
import lessons from "@/store/modules/lessons"
import levels from "@/store/modules/levels";
import errors from "@/store/modules/errors";
import cards from "@/store/modules/cards";
import cardModal from "@/store/modules/cardModal";
import questionTypes from "@/store/modules/questionTypes";

export default createStore({
  state: () => ({

  }),
  actions: {
  },
  mutations: {
  },
  getters: {
  },
  modules: {
    auth: auth,
    admins: admins,
    themes: themes,
    lessons: lessons,
    levels: levels,
    errors: errors,
    cards: cards,
    cardModal: cardModal,
    questionTypes: questionTypes,
  }
})
