import axios from "axios";
import router from "@/router";

const admins = {
    state: () => ({
        admins: [],

    }),
    mutations: {
        set_admins(state, payload) {
            state.admins = payload
        }
    },
    actions: {
        async getAdmins({commit, state}){
            const admins = await axios.get('admins').then(response => {

                return response.data.data.admins
            })
            commit('set_admins', admins)
        }
    },
    getters: {

    },
    namespaced: true,
}
export default admins;