<template>
  <div class="input-group align-items-center border-class">
    <input type="password" :value="modelValue" @input="updateInput" id="password"
           class="form-control password-input border-0" :placeholder="placeholder">
    <div @click="changePasswdVisibility" class="field-icon mr-10">
      <svg v-if="!filledPassword" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M12 9.11869C10.1945 9.11869 8.72728 10.606 8.72728 12.4361C8.72728 14.2663 10.1945 15.7536 12 15.7536C13.8055 15.7536 15.2727 14.2663 15.2727 12.4361C15.2727 10.606 13.8054 9.11869 12 9.11869Z" fill="#999999"/>
        <path d="M12 4.14252C6.54545 4.14252 1.88729 7.58157 0 12.4361C1.88729 17.2907 6.54545 20.7298 12 20.7298C17.46 20.7298 22.1127 17.2907 24 12.4361C22.1127 7.58157 17.46 4.14252 12 4.14252ZM12 17.9652C8.9891 17.9652 6.54544 15.4881 6.54544 12.4361C6.54544 9.38403 8.9891 6.90704 12 6.90704C15.0109 6.90704 17.4546 9.38409 17.4546 12.4361C17.4546 15.4882 15.0109 17.9652 12 17.9652Z" fill="#999999"/>
      </svg>
      <svg v-if="filledPassword && !showPassword" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M12 9.11875C10.1945 9.11875 8.72728 10.6061 8.72728 12.4362C8.72728 14.2663 10.1945 15.7536 12 15.7536C13.8055 15.7536 15.2727 14.2663 15.2727 12.4362C15.2727 10.6061 13.8054 9.11875 12 9.11875Z" fill="#091E31"/>
        <path d="M12 4.14258C6.54545 4.14258 1.88729 7.58163 0 12.4362C1.88729 17.2907 6.54545 20.7298 12 20.7298C17.46 20.7298 22.1127 17.2907 24 12.4362C22.1127 7.58163 17.46 4.14258 12 4.14258ZM12 17.9652C8.9891 17.9652 6.54544 15.4882 6.54544 12.4361C6.54544 9.38409 8.9891 6.9071 12 6.9071C15.0109 6.9071 17.4546 9.38415 17.4546 12.4362C17.4546 15.4883 15.0109 17.9652 12 17.9652Z" fill="#091E31"/>
      </svg>
      <svg v-if="filledPassword && showPassword" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8173 9.14816L15.2529 12.6297L15.2692 12.4474C15.2692 10.6181 13.8023 9.13158 11.9973 9.13158L11.8173 9.14816Z" fill="#091E31"/>
        <path d="M11.9973 6.92109C15.0075 6.92109 17.4506 9.3969 17.4506 12.4474C17.4506 13.1603 17.3088 13.8401 17.0634 14.4645L20.2536 17.6974C21.9005 16.3048 23.1984 14.5032 24 12.4474C22.1077 7.59534 17.456 4.15795 11.9973 4.15795C10.4704 4.15795 9.00893 4.43425 7.65106 4.93162L10.0069 7.31344C10.623 7.0703 11.2938 6.92109 11.9973 6.92109Z" fill="#091E31"/>
        <path d="M1.09064 3.90921L4.07361 6.93213C2.27402 8.35792 0.850714 10.2535 0 12.4474C1.88686 17.2995 6.54395 20.7369 11.9973 20.7369C13.6878 20.7369 15.302 20.4053 16.7798 19.8029L20.4227 23.5L21.8132 22.0963L2.48123 2.5L1.09064 3.90921ZM7.12202 10.0158L8.80709 11.7234C8.75801 11.9611 8.72529 12.1987 8.72529 12.4474C8.72529 14.2766 10.1922 15.7632 11.9973 15.7632C12.2427 15.7632 12.4772 15.73 12.7062 15.6803L14.3913 17.3879C13.666 17.7526 12.8589 17.9737 11.9973 17.9737C8.98705 17.9737 6.54395 15.4979 6.54395 12.4474C6.54395 11.5742 6.7621 10.7563 7.12202 10.0158Z" fill="#091E31"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'password-input',
  data() {
    return {
      showPassword: false,
    }
  },
  props: {
    modelValue: [String],
    filledPassword: [Boolean],
    placeholder: [String],
  },
  methods: {
    changePasswdVisibility() {
      const passwordInput = document.querySelector('.password-input');
      const isTypePassword = passwordInput.getAttribute('type') === 'password';

      if (passwordInput.value !== '') {
        if (isTypePassword) {
          passwordInput.setAttribute('type', 'text');
          this.showPassword = true;
        } else {
          passwordInput.setAttribute('type', 'password');
          this.showPassword = false;
        }
      }
    },
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>

<style scoped>
.input-group {
  border: 2px solid #E9F4FF;
  border-radius: 0.25rem;
}
.field-icon {
  margin-right: 10px;
}
.form-control {
  border: 2px solid #E9F4FF;
  box-shadow: none;
}
</style>