<template>
    <div class="modal fade" id="cardModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{ update ? 'Редактировать' : 'Создать' }} карточку</h5>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="close" data-dismiss="modal">
                        <path d="M7.32166 6L11.8083 1.51288C11.9317 1.38936 11.9998 1.22457 12 1.04885C12 0.873032 11.9319 0.708046 11.8083 0.584721L11.4151 0.191622C11.2915 0.0678093 11.1267 0 10.9508 0C10.7752 0 10.6104 0.0678093 10.4868 0.191622L6.0002 4.67845L1.51337 0.191622C1.38995 0.0678093 1.22507 0 1.04927 0C0.873659 0 0.70878 0.0678093 0.585366 0.191622L0.192 0.584721C-0.064 0.840738 -0.064 1.25715 0.192 1.51288L4.67873 6L0.192 10.4869C0.0684878 10.6106 0.000487805 10.7754 0.000487805 10.9512C0.000487805 11.1269 0.0684878 11.2917 0.192 11.4153L0.585268 11.8084C0.708683 11.9321 0.873658 12 1.04917 12C1.22498 12 1.38985 11.9321 1.51327 11.8084L6.0001 7.32145L10.4867 11.8084C10.6103 11.9321 10.7751 12 10.9507 12H10.9509C11.1266 12 11.2914 11.9321 11.415 11.8084L11.8082 11.4153C11.9316 11.2918 11.9997 11.1269 11.9997 10.9512C11.9997 10.7754 11.9316 10.6106 11.8082 10.487L7.32166 6Z" fill="#091E31"/>
                    </svg>
                </div>
                <div class="modal-body">
                    <div>
                        <label class="d-block" for="word">Слово</label>
                        <input type="text" id="word" v-model="form.word">
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <label class="d-block" for="value-ru">Значение на украинском</label>
                            <input type="text" id="value-ru" v-model="form.translation_ua">
                        </div>
                        <div>
                            <label class="d-block" for="value-uk">Значение на русском</label>
                            <input type="text" id="value-uk" v-model="form.translation">
                        </div>
                    </div>
                    <div>
                        <label class="d-block" for="lessonName">Урок</label>
                        <select id="lessonName" multiple v-model="form.lessons_id">
                            <option v-for="lesson in lessonsInfo" :value="lesson.id">{{ lesson.name }}</option>
                        </select>
                    </div>
                    <div>
                        <input type="radio" name="status" value="1" id="publish" v-model="form.status">
                        <label for="publish">Опубликовать</label><br>
                        <input type="radio" name="status" value="0" id="draft" checked v-model="form.status">
                        <label for="draft">Черновик</label>
                    </div>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Отмена</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="send">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
    {{ formUpdate }}
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "CardModal",
    data: () => ({
        update: false,
        form: {lessons_id: [], word: '', translation: '', translation_ua: '', status: '0'}
    }),
    methods: {
        ...mapActions({
            getLessons: 'lessons/getLessons',
            createCard: 'cards/createCard',
            updateCard: 'cards/updateCard',
        }),
        send() {
            if (!this.update)
                this.createCard(this.form)
            else
                this.updateCard(this.form)
            this.form = [];
        }
    },
    computed: {
        ...mapState({
            lessons: state => state.lessons.lessons,
            cardModal: state => state.cardModal.form,
        }),
        lessonsInfo() {
            return this.lessons.map(lesson => ({id: lesson.id, name: lesson.name}))
        },
        formUpdate() {
            if (this.cardModal.id !== undefined) {
                this.form = this.cardModal;
                this.form.lessons_id = this.form.lessons.map(item => item.id);
                this.update = true;
            } else {
                this.update = false;
                this.form = {lessons_id: [], word: '', translation: '', translation_ua: '', status: '0'};
            }
        }
    },
    mounted() {
        this.getLessons()
    }
}
</script>

<style scoped>
    .modal-title {
        color: #0d6efd;
        text-align: center;
        width: 90%;
    }
    .btn {
        width: 45%;
        margin: 10px;
    }
    .close:hover {
        cursor: pointer;
    }
</style>