<template>
    <div class="container">
        <div class="first-row">
            Уровень
            <select v-model="theme.level_id">
                <option v-for="level in levels" :value="level.id">
                    {{ level.name }}
                </option>
            </select>
            Название темы
            <input type="text" v-model="theme.name">
        </div>
        <div>
            Описание укр
            <textarea cols="60" rows="7" v-model="theme.description_ua"></textarea>
        </div>
        <div>
            Описание рус
            <textarea cols="60" rows="7" v-model="theme.description"></textarea>
        </div>
        <div>
            Теоретическая часть укр
            <textarea cols="60" rows="7" v-model="theme.theory_ua"></textarea>
        </div>
        <div>
            Теоретическая часть рус
            <textarea cols="60" rows="7" v-model="theme.theory"></textarea>
        </div>
        <div>
            <input type="radio" name="status" value="0" v-model="theme.status"> Черновик
            <input type="radio" name="status" value="1" v-model="theme.status"> Опубликовать
        </div>
        <div class="text-danger" v-show="theme.image !== ''" @click="removeImage">Удалить изображение</div>
        <img :src="theme.image" alt="">
        <div>
            <input type="file" accept="image/*" @change="handleImage" ref="inputFile">
        </div>
        <hr>
        <div>
            <div>
                <span class="text-primary" @click="add">+ Создать финальный тест</span>
            </div>
            <div v-for="(question, qIndex) in theme.questions" :key="qIndex">
                <div>
                    Задание {{ qIndex + 1 }}
                    <span class="text-danger" @click="remove(qIndex)" >Удалить задание</span>
                </div>
                <div>
                    Текст задания
                    <input type="text" class="col-5 m-2" v-model="question.question">
                </div>
                <div v-for="(option, key) in question.options">
                    Ответ {{ key + 1}}
                    <input type="text" class="col-6 m-2" v-model="question.options[key]">
                    <div v-if="typeof question.rightAnswer !== 'object'" class="d-inline">
                        <input type="radio" :name="'answer' + Math.random().toString(5)" :value="key + 1" v-model="question.rightAnswer">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button @click="update">Сохранить</button>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
export default {
    "name": "EditTheme",
    computed: {
        ...mapGetters({
            "getAllThemes": 'themes/getAllThemes',
        }),
        ...mapState({
            levels: state => state.levels.levels,
        }),
    },
    data() {
        return {
            theme: {
                name: '',
                description: '',
                description_ua: '',
                image: '',
                level_id: '',
                theory: '',
                theory_ua: '',
                questions: [],
            },
        }
    },
    methods: {
        ...mapActions({
            'updateTheme': 'themes/updateTheme',
            'getThemeById': 'themes/getThemeById',
            'getLevels': 'levels/getLevels',
        }),
        update() {
            let tmpImage = this.theme.image;
            this.theme.image = this.theme.image.split(',')[1];
            this.updateTheme(this.theme);
            this.theme.image = tmpImage;
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.createBase64Image(selectedImage);
        },
        createBase64Image(file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.theme.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeImage() {
            this.theme.image = '';
            this.$refs.inputFile.value = null;
        },
        add() {
            this.theme.questions.push({
                type: 9,
                question: '',
                options: ['','','',''],
                rightAnswer: 1,
            });
        },
        remove(qNumber) {
            this.theme.questions.splice(qNumber, 1)
        },
    },
    mounted() {
        this.getLevels()
        this.getThemeById(this.$route.params.id)
            .then(result => {
                this.theme = result;
            })
    }
}
</script>

<style scoped>
.first-row {
    margin-bottom: 40px;
}
</style>