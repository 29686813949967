import Sidebar from "./SideBar.vue";
// import SidebarLink from "./SidebarLink";
// import SidebarDropdown from "@/components/SidebarDropdown";

const SidebarStore = {
    showSidebar: false,
    sidebarLinks: [],
    displaySidebar(value) {
        this.showSidebar = value;
    }
};

const SidebarSubmenu = {
    isOpen: true,

    displaySubmenu(value){
        this.isOpen = value;
    }
}

export default {
    install (app, options){
        app.config.globalProperties.$sidebarStore = SidebarStore
        app.config.globalProperties.$sidebarSubmenu = SidebarSubmenu
        app.component("side-bar", Sidebar);
        // Vue.component("sidebar-link", SidebarLink);
        // Vue.component("sidebar-dropdown", SidebarDropdown);
    }
};