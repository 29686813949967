<template>
    <main-template :form="form" @updateClick="update"/>
</template>

<script>
import {mapActions} from "vuex";
import MainTemplate from "@/components/Lessons/MainTemplate";

export default {
    name: "EditLesson",
    components: {MainTemplate},
    data() {
        return {
            form: {
                theme: 1,
                name: '',
                description: '',
                description_ua: '',
                questions: [],
                status: 0,
            },
        }
    },
    methods: {
        ...mapActions({
            'getLessonById': 'lessons/getLessonById',
            'updateLesson': 'lessons/updateLesson',
            'getThemes': 'themes/getThemes',
        }),
        update(form) {
            let tmpArr = [];
            form.questions.forEach((question, qKey) => {
                if (question.type === 3 || question.type === 8) {
                    question.images.forEach((image, iKey) => {
                        tmpArr.push(image)
                        form.questions[qKey].images[iKey] = form.questions[qKey].images[iKey].split(',')[1];
                    })
                }
            })

            form.theme_id = form.theme;
            this.updateLesson(form)

            form.questions.forEach((question, qKey) => {
                if (question.type === 3 || question.type === 8) {
                    question.images.forEach((image, iKey) => {
                        form.questions[qKey].images[iKey] = tmpArr.shift();
                    })
                }
            })
        },
    },
    mounted() {
        this.getThemes();
        this.getLessonById(this.$route.params.id)
            .then(result => {
                result.questions.forEach((question, key) => {
                    result.questions[key].number = key;
                })
                this.form = result;
            })
    }
}
</script>

<style scoped>

</style>