<template>
    <main-template :form="form" @updateClick="create"/>
</template>

<script>
import {mapActions} from "vuex";
import MainTemplate from "@/components/Lessons/MainTemplate";

export default {
    name: "CreateLesson",
    components: {MainTemplate},
    data() {
        return {
            form: {
                theme: 1,
                name: '',
                description: '',
                description_ua: '',
                questions: [],
                status: 0,
            },
        }
    },
    methods: {
        ...mapActions({
           'createLesson': 'lessons/createLesson',
            'getThemes': 'themes/getThemes',
        }),
        create(form) {
            let tmpArr = [];
            form.questions.forEach((question, qKey) => {
                if (question.type === 3 || question.type === 8) {
                    question.images.forEach((image, iKey) => {
                        tmpArr.push(image)
                        form.questions[qKey].images[iKey] = form.questions[qKey].images[iKey].split(',')[1];
                    })
                }
            })

            form.theme_id = form.theme; // todo ???
            this.createLesson(form)

            form.questions.forEach((question, qKey) => {
                if (question.type === 3 || question.type === 8) {
                    question.images.forEach((image, iKey) => {
                        form.questions[qKey].images[iKey] = tmpArr.shift();
                    })
                }
            })
        },
    },
    mounted() {
        this.getThemes();
    }
}
</script>

<style scoped>

</style>