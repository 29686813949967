<template>
    <div class="first-row">
        Тема урока
        <select v-model="form.theme">
            <option v-for="themeName in getAllThemesNames" :value="themeName.id">
                {{ themeName.name }}
            </option>
        </select>
        Название урока
        <input type="text" v-model="form.name">
    </div>
    <div>
        Описание укр
        <textarea cols="50" rows="8" v-model="form.description_ua"></textarea>
    </div>
    <div>
        Описание рус
        <textarea cols="50" rows="8" v-model="form.description"></textarea>
    </div>
    <div>
        <input type="radio" name="status" value="0" v-model="form.status" checked> Черновик
        <input type="radio" name="status" value="1" v-model="form.status"> Опубликовать
    </div>
    <hr>
    <div>
        <div>
            <span class="text-primary" @click="add">+ Создать</span>
        </div>
        <div v-for="(question, qIndex) in form.questions" :key="qIndex">
            <div>
                Задание {{ qIndex + 1 }}
                <span class="text-danger" @click="remove(qIndex)" >Удалить задание</span>
            </div>
            <select v-model="question.type" @change="changeType(question.number, question.type)">
                <option :value="questionType.id" v-for="questionType in questionTypeGetter">
                    {{ questionType.title }}
                </option>
            </select>
            <div v-if="question.type !== 6">
                Текст задания
                <input type="text" class="col-5 m-2" v-model="question.question">
            </div>
            <div v-if="question.type === 7">
                Текст задания на украинском
                <input type="text" class="col-5 m-2" v-model="question.question_ua">
            </div>
            <div v-for="(image, imgIndex) in question.images" class="d-inline">
                <div class="text-danger" v-show="question.images !== null && question.images.length !== 0" @click="removeImage(qIndex, imgIndex)">Удалить изображение</div>
                <img :src="image" alt="">
            </div>
            <div v-show="question.images != null">
                Загрузить изображение
                <input type="file" accept="image/*" @change="handleImage($event, qIndex)" ref="inputFile" multiple>
            </div>
            <div v-if="question.type === 2 || question.type === 6" @input="removeSymbols(question.text, qIndex)">
                Текст аудио
                <input type="text" class="col-5 m-2" v-model="question.text">
            </div>
            <div v-show="typeof question.options == 'object' && question.type !== 6 && question.type !== 8" v-for="(option, key) in question.options">
                <div class="d-inline" v-if="question.type === 5">
                    <div class="d-inline" v-if="key < 4">
                        Пара {{ key + 1 }}
                        <input type="text" class="m-2" v-model="question.options[key]">
                        <input type="text" class="m-2" v-model="question.options[key + 4]">
                    </div>
                </div>
                <div v-else>
                    Ответ {{ key + 1}}
                    <input type="text" class="col-6 m-2" v-model="question.options[key]">
                    <div v-if="typeof question.rightAnswer !== 'object'" class="d-inline">
                        <input type="radio" :name="'answer' + Math.random().toString(5)" :value="key + 1" v-model="question.rightAnswer">
                    </div>
                    <div v-else class="d-inline">
                        <input type="checkbox" :value="key + 1" v-model="question.rightAnswer">
                    </div>
                </div>
            </div>
            <div v-show="question.type === 6 || question.type === 8">
                Ответ
                <input type="text" v-model="question.rightAnswer">
            </div>
        </div>
        <button @click="update">Сохранить</button>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'MainTemplate',
    props: {form: Object},
    computed: {
        ...mapGetters({
            "getAllThemesNames": 'themes/getAllThemesNames',
            "questionTypeGetter": 'questionTypes/questionTypeGetter',
        }),
        ...mapState({
            questionTypes: state => state.questionTypes.questionTypes,
        }),
    },
    methods: {
        ...mapActions({
           'getQuestionTypes': 'questionTypes/getQuestionTypes',
        }),
        update() {
            if (!this.form.questions.length && this.form.status == 1) {
                alert('Сохранять можно только уроки с вопросами')
                return;
            }

            this.$emit('updateClick', this.form);
        },
        add() {
            this.form.questions.push({
                number: this.form.questions.length + 1,
                type: 1,
                question: '',
                options: ['', '', '', ''],
                rightAnswer: 1,
                text: '',
                images: null,
                question_ua: '',
            });
        },
        removeImage(questionIndex, imageIndex) {
            this.form.questions[questionIndex].images.splice(imageIndex, 1);
            Object.values(this.$refs.inputFile[0].files).splice(imageIndex, 1);
        },
        remove(qNumber) {
            this.form.questions.splice(qNumber, 1)
        },
        changeType(number, type) {
            let question = this.form.questions[number - 1];
            question.images = null;
            question.options = ['', '', '', ''];
            question.question = '';
            question.question_ua = '';
            if ([1,2,7].includes(type)) {
                question.rightAnswer = 1;
            } else if (type === 3) {
                question.rightAnswer = 1;
                question.images = [];
            } else if (type === 4) {
                question.options = ['', '', '', '','', '', '', ''];
                question.rightAnswer = [];
            } else if (type === 5) {
                question.options = ['', '', '', '','', '', '', ''];
                question.rightAnswer = [];
            } else if (type === 6) {
                question.question = 'question'
                question.options = ['1'];
                question.rightAnswer = '';
            } else if (type === 8) {
                question.images = [];
                question.rightAnswer = '';
                question.options = ['1'];
            }
        },
        handleImage(e, index) {
            const selectedImages = e.target.files;
            //todo move loop from cratebase64 function to here
            this.createBase64Images(selectedImages, index);
        },
        createBase64Images(files, index) {
            Object.keys(files).forEach(i => {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.form.questions[index].images.push(e.target.result);
                };
                reader.readAsDataURL(files[i]);
            });
        },
        removeSymbols(text, index) {
            let length = text.length;
            let last = text.substr(length - 1, 1)
            if(/[А-Яа-яёЁЇїІіЄєҐґ]/.test(last))
                this.form.questions[index].text = this.form.questions[index].text.substr(0, length - 1);
        }
    },
    mounted() {
        this.getQuestionTypes();
    }
}
</script>

<style scoped>
.first-row {
    margin: 30px;
}
</style>