<template>
    <div class="wrapper d-flex flex-row">
        <side-bar class="bl-sidebar-wrapper"/>
        <div class="main-panel">
            <top-navbar class="bl-top-navbar-wrapper"></top-navbar>
<!--            <dashboard-content @click.native="toggleSidebar"></dashboard-content>-->
            <dashboard-content class="bl-content-wrapper"></dashboard-content>
<!--            <content-footer class="footer-wrapper"></content-footer>-->
        </div>
    </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./Footer.vue";
import DashboardContent from "./Content.vue";
export default {
    name: "DashboardLayout",
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent
    }
}
</script>

<style scoped>

</style>