<template>
  <locale-dropdown/>
  <div class="form_wrap d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="image">
        <img src="@/assets/sloth.svg" alt="sloth">
      </div>
      <form @submit.prevent>
        <error-block v-if="error">{{ $t('login.errors.'+errorMessage) }}</error-block>
        <div class="form-group">
          <label for="username">{{ $t('login.username.label') }}</label>
          <input type="text" v-model="form.username" class="form-control border-class" :class="{'error': error}" id="username" :placeholder="$t('login.username.placeholder')">
        </div>
        <div class="form-group password">
          <label for="password">{{ $t('login.password.label') }}</label>
          <password-input :placeholder="$t('login.password.placeholder')" v-model="form.password" :class="{'error': error}" :filledPassword="filledPassword"/>
        </div>
        <router-link to="/password-recovery" class="float-end text-decoration-none forget-password">{{ $t('login.forgot') }}?</router-link>
        <submit-button @submit="loginUser" :active-button="activeButton">{{ $t('login.continue') }}</submit-button>
      </form>
    </div>
  </div>
</template>

<script>
import LocaleDropdown from "@/components/form/localeDropdown";
import ErrorBlock from "@/components/errorBlock";
import PasswordInput from "@/components/form/passwordInput";
import SubmitButton from "@/components/form/submitButton";
import { mapActions } from 'vuex'
import axios from 'axios';
export default {
  components: {SubmitButton, ErrorBlock, LocaleDropdown, PasswordInput},
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      activeButton: false,
      filledPassword: false,
      error: false,
        errorMessage: ''
    }
  },
  methods: {
      ...mapActions({
          login: 'auth/login',
      }),

      loginUser() {
          this.login(this.form)
              .then(response => {
                  this.error = false
                  this.errorMessage = ''
                  this.$router.push('/')
              })
              .catch(error => {
                  this.error = true
                  this.errorMessage = error.response.data.message
              })
      }
  },
  watch: {
    form: {
      handler(form) {
        if (form.username !== '' && form.password !== '')
          return [this.activeButton = true, this.filledPassword = true];
        else if (form.password !== '')
          return [this.filledPassword = true, this.activeButton = false];
        else
          return [this.filledPassword = false, this.activeButton = false];
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.form-control {
  border: 2px solid #E9F4FF;
  box-shadow: none;
}
.form-group.password {
  margin-top: 30px;
}
.container {
  width: 343px;
}
label {
  color: #999999;
  font-family: 'Open Sans', 'sans-serif', 'serif';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.form_wrap {
  min-height: 100vh;
}
.error {
    border-color: #F64545;
}
</style>