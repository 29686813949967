<template>
    <div class="bl-table">
        <div  class="bl-table-head">
            <slot name="th"></slot>
        </div>
        <slot name="tb"></slot>
    </div>
</template>

<script>
export default {
    name: "TableComponent"
}
</script>

<style lang="scss" scoped>
.bl-table {
    width: 100%;
    height:100%;
    border: 1px solid #E9F4FF;
    border-radius: 12px;
    margin-top: 16px;

    .bl-table-head {
        border-bottom: 1px solid #E9F4FF;
        padding: 16px;
        font-family: 'Open Sans', 'serif';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #091E31;
    }
}
</style>