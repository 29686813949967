<template>
    <div>Drafts</div>
</template>

<script>
export default {
    name: "DraftsPage"
}
</script>

<style scoped>

</style>