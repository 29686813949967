<template>
  <locale-dropdown :current-lang="currentLang" :other-langs="otherLangs"/>
  <div class="form_wrap d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="image">
        <img src="@/assets/sloth.svg" alt="sloth">
      </div>
      <form @submit.prevent>
        <p class="title">Восстановление пароля</p>
        <div class="form-group password">
        <label for="password">Пароль пользователя</label>
        <password-input :placeholder="'Введите новый пароль'" v-model="password" :filledPassword="filledPassword"/>
      </div>
        <submit-button @submit="sendForm" :active-button="activeButton">Продолжить</submit-button>
        <router-link to="/login" class="btn button w-100 btn-outline-primary">Назад</router-link>
      </form>
    </div>
  </div>
</template>

<script>
import LocaleDropdown from '@/components/form/localeDropdown';
import PasswordInput from "@/components/form/passwordInput";
import SubmitButton from "@/components/form/submitButton";
import axios from "axios";
export default {
  components: {PasswordInput, LocaleDropdown, SubmitButton},
  data() {
    return {
      currentLang: 'RU',
      otherLangs: [
        {'name': "UA",},
      ],
      activeButton: false,
      password: '',
      filledPassword: false,
    }
  },
  methods: {
    sendForm() {
      axios.post('/password/restorePassword', {
        token: this.$route.query.token,
        password: document.getElementById('password').value,
      }).then(response => {
        this.$router.push('/successful-password-recovery');
      }, error => {

      });
    }
  },
  watch: {
    password(newPassword) {
      if (newPassword !== '')
        return [this.activeButton = true, this.filledPassword = true];
      else
        return [this.filledPassword = false, this.activeButton = false];
    },
  }
}
</script>

<style scoped>
.container {
  width: 343px;
}
.button {
  margin-top: 15px;
}
label {
  color: #999999;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.form_wrap {
  min-height: 100vh;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #1E86EA;
}
</style>