<template>
    <div class="bl-sidebar-link">
        <router-link :to="link" exact-active-class="active">
            <slot></slot>
            <div>
                {{$t(title)}}
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SidebarLink",
    props: {
        title: String,
        link: String
    }
}
</script>

<style scoped>

</style>