<template>
    <div class="container">
        <div>
            <router-link to="/lessons/create">+ Создать</router-link>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Уровень</th>
                <th>Тема</th>
                <th>Задания</th>
                <th>Статус</th>
                <th>Edit</th>
                <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="lesson in getAllLessons">
                <td>{{ lesson.id }}</td>
                <td>{{ lesson.name }}</td>
                <td>{{ lesson.levelName }}</td>
                <td>{{ lesson.themeName }}</td>
                <td>{{ lesson.questionsAmount }}</td>
                <td>{{ lesson.status ? 'Опубликовано' : 'Черновик' }}</td>
                <router-link :to="`/lessons/edit/${lesson.id}`">
                    <button>Редактировать</button>
                </router-link>
                <td><button @click="remove(lesson.id)">Удалить</button></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {

    name: "ThemesPage",
    computed: {
        ...mapGetters({
            "getAllLessons": 'lessons/getAllLessons',
        })
    },
    methods: {
        ...mapActions({
            'getLessons': 'lessons/getLessons',
            'deleteLesson': 'lessons/deleteLesson',
        }),
        remove(lessonId) {
            this.deleteLesson(lessonId);
            this.getLessons();
        }
    },
    mounted() {
        this.getLessons();
    }
}
</script>

<style scoped>

</style>