import axios from "axios";

const errors = {
    state: () => ({
        errors: [],

    }),
    mutations: {
        set_errors(state, payload) {
            const result = {}
            payload.forEach(item => {
                if (!result[item["created"]]){
                    result[item["created"]] = []
                }
                result[item["created"]].push(item)
            })
            state.errors = result
        }
    },
    actions: {
        async getErrors({commit}){
            const errors = await axios.get('errors').then(response => {
                return response.data.data
            })
            commit('set_errors', errors)
        }
    },
    getters: {

    },
    namespaced: true,
}

export default errors;